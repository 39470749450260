import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../auth/auth-service';
import { DbService } from '../db.service';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  public CLIENT_NAME = environment.CLIENT_NAME;
  public LOGIN_LOGO_PATH = environment.LOGIN_LOGO_PATH;

  constructor(
    private authService : AuthService,
    private dbservice: DbService,
    private toastr: ToastrService,
  ) {};

  ngOnInit() {
    console.info("deliverynow init");
    this.loginForm = new FormGroup({
      user: new FormControl('', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]),
      pass: new FormControl('', [Validators.required, Validators.minLength(3), Validators.maxLength(30)])
   });
  };

  signIn(user) {
    this.dbservice.login(user)
    .subscribe(
      (res) => {
        if (res['token']) {
          localStorage.setItem('token', res['token']);
          this.authService.logged();
        }
      },
      (err) => {
        this.toastr.error(null, "Usuario y/o password inválidos");
        console.error(err);
      }
    );
  };
  
}