import { animate, state, style, transition, trigger } from '@angular/animations';
import { AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatExpansionPanel } from '@angular/material/expansion';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ToastrService } from 'ngx-toastr';
import { DbService } from '../db.service';
import { DiaglogWebcamComponent } from 'src/app/dialog-webcam/dialog-webcam.component';
import { MatDialog } from '@angular/material/dialog';

export interface Socio {
    id_socio: number;
    nombre: string;
    apellidos: string;
    dni: number;
    aval: number;
    direccion: string;
    filename: string;
}

@Component({
    selector: 'app-socios',
    templateUrl: './socios.component.html',
    styleUrls: ['./socios.component.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0' })),
            state('expanded', style({ height: '*' })),
            transition('expanded <=> collapsed', animate('500ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
        ]),
        trigger("moveAnimation", [
            state("true", style({ 'margin-top': '0%', 'opacity': '100%' })),
            state("false", style({ 'margin-top': '100%', 'opacity': '0%' })),
            transition('true <=> false', animate('1000ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
        ])
    ]
})
export class SociosComponent implements OnInit, AfterViewInit {

    addSocioForm: FormGroup;
    edit: boolean;
    public expandedElement: boolean;

    displayedColumns: string[] = ['id_socio', 'nombre', 'apellidos'];
    displayedColumnsKeys: string[];
    detailedColumns: string[] = ['id_socio', 'nombre', 'apellidos', 'direccion', 'dni', 'aval'];
    dataSource = new MatTableDataSource<Socio>();
    dataSourceBaja = new MatTableDataSource<Socio>();

    public savedSnapshot: boolean = false;
    captureImage: string = '';
    tableDef: Array<any> = [
        {
            key: 'id_socio',
            header: 'Nº Socio'
        }, {
            key: 'nombre',
            header: 'Nombre'
        }, {
            key: 'apellidos',
            header: 'Apellidos'
        }
    ];

    movedListPanel: boolean = false;

    @ViewChild('paginator', { static: true }) paginator: MatPaginator;
    @ViewChild('paginatorBaja', { static: true }) paginatorBaja: MatPaginator;
    @ViewChild(MatExpansionPanel, { static: true }) expPanel: MatExpansionPanel;

    constructor(
        private dbservice: DbService,
        private toastr: ToastrService,
        private matDialog: MatDialog,
        private changeDetector: ChangeDetectorRef
    ) {
        this.addSocioForm = new FormGroup({
            numerosocio: new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(3)]),
            nombre: new FormControl('', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]),
            apellidos: new FormControl('', [Validators.required, Validators.minLength(3), Validators.maxLength(50)]),
            direccion: new FormControl('', [Validators.required, Validators.minLength(5), Validators.maxLength(50)]),
            dni: new FormControl('', [Validators.required, Validators.minLength(8), Validators.maxLength(8)]),
            aval: new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(3)]),
            foto_perfil: new FormControl()
        });
        this.getAllSocios();
    };

    ngOnInit() {
        this.edit = false;
        this.paginator._intl.itemsPerPageLabel = 'Socios por página';
        this.paginatorBaja._intl.itemsPerPageLabel = 'Socios por página';
    };

    ngAfterViewInit() {
        this.dataSource.paginator = this.paginator;
        this.dataSourceBaja.paginator = this.paginatorBaja;
    };

    resetForm() {
        this.addSocioForm.reset();
        this.captureImage = '';
    };

    addSocio() {
        this.addSocioForm.value['foto_perfil'] = this.captureImage;
        this.dbservice.addSocio(this.addSocioForm.value).subscribe(
            (data) => {
                if (data['success'] == 1) {
                    this.captureImage = '';
                    this.getAllSocios();
                    this.resetForm();
                    this.expPanel.close();
                    this.toastr.success(null, data['msg'], {
                        toastClass: 'ngx-toastr toast-correcto'
                    });
                } else {
                    this.toastr.error(null, data['msg']);
                }
            },
            (err) => {
                this.toastr.error(null, err['error']['msg']);
            }
        );
    };

    modifySocio(socio) {
        socio['foto_perfil'] = this.captureImage;
        this.dbservice.updateSocio(socio).subscribe(
            (data) => {
                if (data['success'] == 1) {
                    this.captureImage = '';
                    this.edit = false;
                    this.getAllSocios();
                    this.toastr.success(null, data['msg'], {
                        toastClass: 'ngx-toastr toast-correcto'
                    });
                } else {
                    this.toastr.error(null, data['msg']);
                }
            },
            (err) => {
                this.toastr.error(null, err.message);
            }
        );
    };

    editableSocio() {
        this.edit = true;
    };

    deleteSocio(socio) {
        this.dbservice.deleteSocio(socio).subscribe(
            (data) => {
                if (data['success'] == 1) {
                    this.getAllSocios();
                    this.toastr.success(null, data['msg'], {
                        toastClass: 'ngx-toastr toast-correcto'
                    });
                } else {
                    this.toastr.error(null, data['msg']);
                }
            },
            (err) => {
                this.toastr.error(null, err.message);
            }
        );
    };

    getAllSocios() {
        this.dbservice.getSocios().subscribe((data) => {
            if (data['success'] == 1) {
                this.dataSource.data = data['data'];
                this.edit = false;
                this.movedListPanel = true;
            } else {
                this.toastr.error(null, data['msg']);
            }
        },
            (err) => {
                this.toastr.error(null, err.message);
            }
        );

        this.dbservice.getSociosBaja().subscribe((data) => {
            if (data['success'] == 1) {
                this.dataSourceBaja.data = data['data'];
                this.edit = false;
                this.movedListPanel = true;
            } else {
                this.toastr.error(null, data['msg']);
            }
        },
            (err) => {
                this.toastr.error(null, err.message);
            }
        );
    };

    altaSocio(socio) {
        this.dbservice.altaSocio(socio).subscribe(
            (data) => {
                if (data['success'] == 1) {
                    this.getAllSocios();
                    this.toastr.success(null, data['msg'], {
                        toastClass: 'ngx-toastr toast-correcto'
                    });
                } else {
                    this.toastr.error(null, data['msg']);
                }
            },
            (err) => {
                this.toastr.error(null, err.message);
            }
        );
    };

    applyFilter(filterValue: string) {
        filterValue = filterValue.trim();
        filterValue = filterValue.toLowerCase();
        this.dataSource.filter = filterValue;
    };

    applyFilterBaja(filterValue: string) {
        filterValue = filterValue.trim();
        filterValue = filterValue.toLowerCase();
        this.dataSourceBaja.filter = filterValue;
    };

    takePhoto() {
        const dialogRef = this.matDialog.open(DiaglogWebcamComponent, {});
        dialogRef.afterClosed().subscribe((data) => {
            if (data.image)
                this.captureImage = data.image;
            this.savedSnapshot = true;
        });
    }
    
}