import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import * as jwt_decode from "jwt-decode";
import { AuthService } from './auth/auth-service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: []
})

export class AppComponent {
  title = 'deliverynow';

  public logged: boolean = false;

  constructor(private router: Router, authService: AuthService) {

    authService.isLoggedIn.subscribe(loggedIn => {
      this.logged = loggedIn;
    });
    
    // Controla si la sesion ha caducado cada vez que cambia la ruta
    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.checkTokenExpired();
      }
    });
  }

  checkTokenExpired() {
    var token = localStorage.getItem('token');

    if (token === null) {
      this.logged = false;
      return;
    }

    var tokenInfo = this.getDecodedAccessToken(token);
    if (tokenInfo.exp > new Date().getTime() / 1000) {
      this.logged = true;
    } else {
      this.logged = false;
      localStorage.removeItem('token');
    }
  }

  private getDecodedAccessToken(token: string): any {
    try {
      return jwt_decode(token);
    }
    catch (Error) {
      return null;
    }
  }

}
