<div>
    <div class="text-center">
        <mat-form-field class="text-center">
            <input id="membernumber" #membernumber autocomplete="off" matInput [(ngModel)]="data.socioNum" placeholder="Número de socio">
        </mat-form-field>
    </div>
    <div>
        <button id="submit" #submit class="w-100" type="button" mat-button [mat-dialog-close]="data.socioNum"
            cdkFocusInitial>OK</button>
    </div>
</div>