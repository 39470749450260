import { animate, state, style, transition, trigger } from '@angular/animations';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatExpansionPanel } from '@angular/material/expansion';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ToastrService } from 'ngx-toastr';
import { DbService } from '../db.service';

export interface Variedad {
  id_variedad: number;
  nombre: string;
  cantidad: number;
  aporte: number;
}

@Component({
  selector: 'app-almacen',
  templateUrl: './almacen.component.html',
  styleUrls: ['./almacen.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('500ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
    ]),
    trigger("moveAnimation", [
      state("true", style({ 'margin-top': '0%', 'opacity': '100%' })),
      state("false", style({ 'margin-top': '100%', 'opacity': '0%' })),
      transition('true <=> false', animate('1000ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
    ])
  ],
})
export class AlmacenComponent implements OnInit, AfterViewInit {

  public expandedElement: boolean;

  addVariedadForm: FormGroup;
  edit: Boolean;
  displayedColumns: string[] = ['nombre', 'cantidad', 'aporte'];
  detailedColumns: string[] = ['id_variedad', 'nombre', 'cantidad', 'aporte'];
  dataSource = new MatTableDataSource<Variedad>();

  tableDef: Array<any> = [
    {
      key: 'nombre',
      header: 'Nombre'
    }, {
      key: 'cantidad',
      header: 'Cantidad'
    }, {
      key: 'aporte',
      header: 'Aporte'
    }
  ];

  movedListPanel: boolean = false;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatExpansionPanel, { static: true }) expPanel: MatExpansionPanel;

  constructor(private dbservice: DbService, private toastr: ToastrService) {
    this.addVariedadForm = new FormGroup({
      nombre: new FormControl('', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]),
      cantidad: new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(5)]),
      aporte: new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(5)])
    });
    this.updateAllVariedades();
  };

  ngOnInit() {
    this.edit = false;
    this.paginator._intl.itemsPerPageLabel = 'Variedades por página';
  };

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  };

  resetForm() {
    this.addVariedadForm.reset();
  };

  addVariedad() {
    this.dbservice.addVariedad(this.addVariedadForm.value).subscribe(
      (data) => {
        if (data['success'] == 1) {
          this.updateAllVariedades();
          this.resetForm();
          this.expPanel.close();
          this.toastr.success(null, data['msg'], {
            toastClass: 'ngx-toastr toast-correcto'
          });
        } else {
          this.toastr.error(null, data['msg']);
        }
      },
      (err) => {
        this.toastr.error(null, err.message);
      }
    );
  };

  modifyVariedad(variedad) {
    this.dbservice.updateVariedad(variedad).subscribe(
      (data) => {
        if (data['success'] == 1) {
          this.edit = false;
          this.updateAllVariedades();
          this.toastr.success(null, data['msg'], {
            toastClass: 'ngx-toastr toast-correcto'
          });
        } else {
          this.toastr.error(null, data['msg']);
        }
      },
      (err) => {
        this.toastr.error(null, err.message);
      }
    );
  };

  editableVariedad() {
    this.edit = true;
  };

  isAporte(column) {
    return column.key == 'aporte';
  };

  deleteVariedad(socio) {
    this.dbservice.deleteVariedad(socio).subscribe(
      (data) => {
        if (data['success'] == 1) {
          this.updateAllVariedades();
          this.toastr.success(null, data['msg'], {
            toastClass: 'ngx-toastr toast-correcto'
          });
        } else {
          this.toastr.error(null, data['msg']);
        }
      },
      (err) => {
        this.toastr.error(null, err.message);
      }
    );
  };

  updateAllVariedades() {
    this.dbservice.getVariedades().subscribe((data) => {
      if (data['success'] == 1) {
        this.dataSource.data = data['data'];
        this.edit = false;
        this.movedListPanel = true;
      } else {
        this.toastr.error(null, data['msg']);
        return false;
      }
    },
      (err) => {
        this.toastr.error(null, err.message);
      }
    );
  };

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  };

}
