<p class="w-100 name text-center">{{socioName || '...'}}</p>
<div class="flex flex-col h-100 info p-10">
    <div class="row">
        <div class="text-center column p-10">Variedad</div>
        <div class="text-center column p-10">Cantidad</div>
        <div class="text-center column p-10">Aportación</div>
    </div>
    <div class="row scroll-y" *ngFor="let data of dataFromDb; let i = index"
    [style.background-color]="i % 2 === 0 ? '#3F661E' : ''">
        <div class="text-center column p-10">{{data.nombre_variedad}}</div>
        <div class="text-center column p-10">{{data.cantidad}} grs</div>
        <div class="text-center column p-10">{{data.aportacion}} €</div>
    </div>
    <div class="row">
        <div class="text-center column p-10">TOTAL</div>
        <div class="text-center column p-10">{{cantidad || '...'}} grs</div>
        <div class="text-center column p-10">{{aportacion || '...'}} €</div>
    </div>
    <button (click)="dispensar()" type="submit" mat-raised-button
        class="action-button w-100 dispensar-button">
        <mat-icon>control_point</mat-icon>
    </button>
</div>