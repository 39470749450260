import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DbService } from '../db.service';

export interface SocioNumDialogData {
  socioNum: string;
}

@Component({
  selector: 'app-dialog-data-socio',
  templateUrl: './dialog-data-socio-dialog.component.html',
  styleUrls: ['./dialog-data-socio-dialog.component.scss']
})
export class DataSocioDialogComponent implements OnInit {

  dataFromDb: any;
  socioName: string;
  cantidad: number = 0;
  aportacion: number = 0;

  constructor(
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dbService: DbService
  ) { }

  onNoClick() {
    this.dialogRef.close();
  }

  ngOnInit() {
    this.dbService.getTodayAportacionesSocio(this.data.idSocio).subscribe(data => {
      this.dataFromDb = data['data'];
      this.socioName = this.dataFromDb[0].nombre + " " + this.dataFromDb[0].apellidos;

      this.dataFromDb.forEach(element => {
        this.cantidad += element.cantidad;
        this.aportacion += element.aportacion;
      });
    })
  }

  dispensar() {
    this.dialogRef.close({ dispensar: true });
  }

}