import { animate, state, style, transition, trigger } from '@angular/animations';
import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../environments/environment';
import { DbService } from '../db.service';
import { DataSocioDialogComponent } from '../dialog-data-socio/dialog-data-socio-dialog.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  animations: [
    trigger("moveAnimation", [
      state("true", style({ 'margin-top': '0%', 'opacity': '100%' })),
      state("false", style({ 'margin-top': '100%', 'opacity': '0%' })),
      transition('true <=> false', animate('1000ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
    ])
  ]
})
export class HomeComponent implements OnInit {

  socios: number[];
  consumitions: number = 0;
  public CLIENT_NAME = environment.CLIENT_NAME;
  public LOGO_PATH = environment.LOGO_PATH;

  movedListPanel: boolean = false;

  constructor(
    private dbservice: DbService,
    private toastr: ToastrService,
    private matDialog: MatDialog,
    private router: Router
  ) { }

  ngOnInit() {
    this.aportacionesHoy();
    this.consumicionesHoy();
  }

  aportacionesHoy() {
    this.movedListPanel = true;
    this.dbservice.aportacionesHoy().subscribe(
      (data) => {
        if (data['success'] == 1) {
          this.socios = data['data'];
        } else {
          this.toastr.error(null, data['msg']);
        }
      },
      (err) => {
        this.toastr.error(null, err.message);
      }
    );
  };

  consumicionesHoy() {
    let today = formatDate(new Date(), 'yyyy-MM-dd', 'es');
    let dates = { startDate: today, endDate: today }
    this.dbservice.getConsumiciones(dates).subscribe(
      (data) => {
        if (data['success'] == 1) {
          this.consumitions = data['data'][0].consumiciones;
        } else {
          this.toastr.error(null, data['msg']);
        }
      },
      (err) => {
        this.toastr.error(null, err.message);
      }
    );
  }

  newConsumition() {
    this.dbservice.addConsumicion().subscribe(
      (data) => {
        if (data['success'] == 1) {
          this.consumitions++;
        } else {
          this.toastr.error(null, data['msg']);
        }
      },
      (err) => {
        this.toastr.error(null, err.message);
      }
    );
  }

  cerrarDia() {
    this.dbservice.downloadAportacionesHoy().subscribe(
      (data) => {
        if (data['success'] == 1) {
          if (data['data'].length > 0) {
            this.toastr.success(null, data['msg'], {
              toastClass: 'ngx-toastr toast-correcto'
            });
            this.saveToPDF(data['data']);
          } else {
            this.toastr.error(null, 'No hay aportaciones que exportar');
          }
        } else {
          this.toastr.error(null, data['msg']);
        }
      },
      (err) => {
        this.toastr.error(null, err.message);
      }
    );
  }

  showSocioData(idSocio) {
    const dialogRef = this.matDialog.open(DataSocioDialogComponent, {
      width: "50%",
      data: { idSocio }
    });

    dialogRef.afterClosed().subscribe((data) => {
      if (data.dispensar === true)
        this.openDispenser(idSocio)
    });

  }

  private saveToPDF(data) {
    let dateToday = data[0]['fecha_aportacion'];
    let today = formatDate(dateToday, 'dd/MM/yyyy', 'es-ES');

    var headers = [['Socio', 'Variedad', 'Cantidad (grs)', 'Aportado (€)']];
    var totalAportado: number = 0;
    var totalCantidad: number = 0;
    let table = [];
    for (let i = 0; i < data.length; i++) {
      table.push([data[i]['id_socio'], data[i]['nombre_variedad'], data[i]['cantidad'], data[i]['aportacion']]);
      totalCantidad += parseFloat(data[i]['cantidad']);
      totalAportado += parseFloat(data[i]['aportacion']);
    }

    const doc = new jsPDF('l', 'mm', 'a4');
    var logo = new Image();
    logo.src = this.LOGO_PATH;

    doc.setFontSize(10);
    doc.addImage(logo, 'png', 13, 5, 50, 50)
    doc.text(this.CLIENT_NAME + " - Aportaciones", doc.internal.pageSize.getWidth() - 60, 10);
    doc.text("Fecha: " + today, doc.internal.pageSize.getWidth() - 60, 20);
    doc.text("Cantidad: " + totalCantidad.toString() + " grs", doc.internal.pageSize.getWidth() - 60, 30);
    doc.text("Aportado: " + totalAportado.toString() + " €", doc.internal.pageSize.getWidth() - 60, 40);
    doc.text("Consumiciones: " + this.consumitions.toString() + " €", doc.internal.pageSize.getWidth() - 60, 50);
    autoTable(doc, {
      head: headers,
      body: table,
      startY: 60,
      headStyles: { fillColor: [92, 151, 43] },
      styles: { fontSize: 10 },
      didDrawCell: (data) => { },
    });

    doc.output('dataurlnewwindow');
  }

  openDispenser(idSocio) {
    this.router.navigate(['dispensario', idSocio])
  }


  onClick(event: any) {
  }

}