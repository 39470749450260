import { animate, state, style, transition, trigger } from '@angular/animations';
import { formatDate } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { DbService } from '../db.service';
import { Chart, ChartConfiguration, ChartType } from 'chart.js';
import { default as Annotation } from 'chartjs-plugin-annotation';
import { BaseChartDirective } from 'ng2-charts';

moment.locale('es');

@Component({
  selector: 'app-estadisticas',
  templateUrl: './estadisticas.component.html',
  styleUrls: ['./estadisticas.component.scss'],
  animations: [
    trigger("moveAnimation", [
      state("true", style({ 'margin-top': '0%', 'opacity': '100%' })),
      state("false", style({ 'margin-top': '100%', 'opacity': '0%' })),
      transition('true <=> false', animate('1000ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
    ])
  ]
})

export class EstadisticasComponent implements OnInit {

  start = null;
  end = null;
  aportacion: number = 0;
  aportacionSocio = "0";
  aportacionDia = "0";
  totalGr: number = 0;
  totalMembers: number = 0;
  totalAportado: number = 0;
  grPerMember: number = 0;
  aportadoPerMember: number = 0;
  aportadoPerDay: number = 0;

  @ViewChild(BaseChartDirective) chart: BaseChartDirective;

  locale = {
    daysOfWeek: moment.weekdaysMin(),
    monthNames: moment.monthsShort(),
    firstDay: moment.localeData().firstDayOfWeek(),
    applyLabel: 'Cargar',
    format: 'dd/MM/yyyy'
  };

  movedListPanel: boolean = false;

  public lineChartType: ChartType = 'line';

  public lineChartData: ChartConfiguration<'line'>['data'] = {
    labels: [],
    datasets: [
      {
        data: [],
        label: 'Gramos por socio',
        fill: true,
        tension: 0.5,
        borderColor: 'green',
        pointBackgroundColor: 'rgba(10, 200, 29, 1)',
        pointBorderColor: 'green',
        pointRadius: 6,
        pointHoverRadius: 6,
        backgroundColor: 'rgba(10, 148, 29, 0.3)',
        hidden: false
      },
      {
        data: [],
        label: 'Aportado por socio',
        fill: true,
        tension: 0.5,
        borderColor: '#25772B',
        pointBackgroundColor: 'rgba(10, 200, 29, 1)',
        pointBorderColor: '#25772B',
        pointRadius: 6,
        pointHoverRadius: 6,
        backgroundColor: '#85bc2230',
        hidden: true
      }
    ]
  };

  public lineChartOptions: ChartConfiguration['options'] = {
    responsive: true,
    elements: {
      line: {
        tension: 0.5
      }
    },
    scales: {
      y:
      {
        position: 'left',
        type: 'linear'
      }
    },
    plugins: {
      legend: {
        display: true,
        onClick: (_, item) => {
          if (!this.chart) {
            return;
          }
          const currentHidden: boolean = this.chart.data.datasets[item.datasetIndex].hidden ?? false;
          if (currentHidden) {
            this.chart.data.datasets.forEach(ds => {
              ds.hidden = true;
            });
            this.chart.data.datasets[item.datasetIndex].hidden = false;
          } else {
            this.chart.data.datasets.forEach(ds => {
              ds.hidden = currentHidden == (ds.hidden ?? false);
            });
            this.chart.data.datasets[item.datasetIndex].hidden = false;
          }
          
          if (item.datasetIndex === 0){
            this.chart.chart.options.plugins.annotation.annotations[0]['display'] = true;
            this.chart.chart.options.plugins.annotation.annotations[1]['display'] = false;
          } else if (item.datasetIndex === 1) {
            this.chart.chart.options.plugins.annotation.annotations[0]['display'] = false;
            this.chart.chart.options.plugins.annotation.annotations[1]['display'] = true;
          }

          this.chart?.chart.update();
        }
      },
      annotation: {
        annotations: [{
          display: true,
          drawTime: 'afterDraw',
          type: 'line',
          xMin: 0,
          yMin: 0,
          yMax: 0,
          borderColor: '#B1872E',
          borderWidth: 2,
          label: {
            display: true,
            rotation: 'auto',
            position: 'center',
            backgroundColor: '#B1872E',
            borderColor: '#B1872E',
            content: 'Media gr/socio',
            color: '#393939'
          }
        },
        {
          display: true,
          drawTime: 'afterDraw',
          type: 'line',
          xMin: 0,
          yMin: 0,
          yMax: 0,
          borderColor: '#B1872E',
          borderWidth: 2,
          label: {
            display: true,
            rotation: 'auto',
            position: 'center',
            backgroundColor: '#B1872E',
            borderColor: '#B1872E',
            content: 'Media aportado/socio',
            color: '#393939'
          }
        }]
      }
    }
  };


  constructor(private dbservice: DbService, private toastr: ToastrService) {
    Chart.register(Annotation)
  }

  ngOnInit() {
    this.dateSelected({ startDate: moment().subtract(7, 'days').startOf('day'), endDate: moment() })
  }

  dateSelected(event) {
    // Formato de la base de datos yyyy-MM-dd
    this.start = formatDate(event.startDate.toDate(), 'yyyy-MM-dd', 'es-ES');
    this.end = formatDate(event.endDate.toDate(), 'yyyy-MM-dd', 'es-ES');

    this.dbservice.estadisticas({ startDate: this.start, endDate: this.end }).subscribe(
      (data) => {
        this.movedListPanel = true;
        if (data['success'] == 1) {
          let dataStatistics = data['data'];
          if (dataStatistics.length > 0){
            this.lineChartData.datasets[0].data.length = 0;
            this.lineChartData.datasets[1].data.length = 0;
            this.lineChartData.labels.length = 0;
            this.totalGr = dataStatistics.map(dataStatistics => dataStatistics.gramos).reduce((acc, amount) => acc + amount);
            this.totalMembers = dataStatistics.map(dataStatistics => dataStatistics.socios).reduce((acc, amount) => acc + amount);
            this.totalAportado = dataStatistics.map(dataStatistics => dataStatistics.aportacion).reduce((acc, amount) => acc + amount);
            this.grPerMember = Math.round(this.totalGr / this.totalMembers * 100) / 100
            this.aportadoPerMember = Math.round(this.totalAportado / this.totalMembers * 100) / 100;
            this.aportadoPerDay = Math.round(this.totalAportado / dataStatistics.length * 100) / 100;
  
            for (let i = 0; i < dataStatistics.length; i++) {
              this.lineChartData.datasets[0].data.push(dataStatistics[i]['gramos'] / dataStatistics[i]['socios']);
              this.lineChartData.datasets[1].data.push(dataStatistics[i]['aportacion'] / dataStatistics[i]['socios']);
              this.lineChartData.labels.push(dataStatistics[i]['fecha_aportacion']);
              this.lineChartOptions.plugins.annotation.annotations[0]['display'] = !this.chart.data.datasets[0].hidden
              this.lineChartOptions.plugins.annotation.annotations[1]['display'] = !this.chart.data.datasets[1].hidden
            }
  
            this.lineChartOptions.plugins.annotation.annotations[0]['yMax'] = this.grPerMember;
            this.lineChartOptions.plugins.annotation.annotations[0]['yMin'] = this.grPerMember;
            this.lineChartOptions.plugins.annotation.annotations[1]['yMax'] = this.aportadoPerMember;
            this.lineChartOptions.plugins.annotation.annotations[1]['yMin'] = this.aportadoPerMember;
  
            this.lineChartOptions = {
              ...this.lineChartOptions
            };
  
            this.chart?.chart.update()
          } else {
            this.toastr.error(null, "No hay datos para el dia seleccionado");
          }
        } else {
          this.toastr.error(null, data['msg']);
        }
      },
      (err) => {
        this.toastr.error(null, err.message);
      }
    );
  };

}
