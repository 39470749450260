<div class=login-container>
    <mat-card class="login-card">
        <mat-card-title class="login-title">
            <h1 class="login-title-text">{{CLIENT_NAME}}</h1>
        </mat-card-title>
        <mat-card-content>
            <div class="text-center">
                <img alt="Logo" class="profile-image" src={{LOGIN_LOGO_PATH}}>
            </div>
            <form [formGroup]="loginForm" (ngSubmit)="signIn(loginForm.value)">
                <mat-form-field class="login-item">
                    <input id="user" type="text" formControlName="user" matInput placeholder="Usuario">
                </mat-form-field>
                <mat-form-field class="login-item">
                    <input id="pass" type="password" formControlName="pass" matInput placeholder="Contraseña">
                </mat-form-field>
                <button type="submit" mat-raised-button class="login-button"
                    [disabled]="loginForm.invalid">Login</button>
            </form>
        </mat-card-content>
    </mat-card>
</div>