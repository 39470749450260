import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SocioNumDialogComponent } from 'src/app/dialogs/socio-num-dialog.component';
import { DbService } from '../db.service';

export interface Socio {
  id_socio: 0,
  nombre: ""
  apellidos: "",
  dni: "",
  aval: 0,
  direccion: "",
  fecha_alta: null,
  fecha_renovacion: null,
  fecha_baja: null,
  filename: null
}

@Component({
  selector: 'app-dispensario',
  templateUrl: './dispensario.component.html',
  styleUrls: ['./dispensario.component.scss']
})
export class DispensarioComponent implements OnInit {

  public orders = [];
  public totalAportacion = 0;
  public totalQuantity = 0;

  public contributing: boolean = false;

  public socio = { id_socio: 0, nombre: '', apellidos: '', fecha_alta: '', filename: '' }; // obtenido de la db

  public formQuantity = 0.5;

  public selectedVariety = null;

  public variedades = [];

  captureImage = '';
  loaded_socio: boolean = false;
  
  constructor(private dbservice: DbService,
    private toastr: ToastrService,
    private matDialog: MatDialog,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {

  }

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      if (params['id']) {
        this.getSocioInfo(params['id']);
        this.loaded_socio = true;
      } else {
        this.openDialog();
      }
    });
  }

  openDialog() {
    const dialogRef = this.matDialog.open(SocioNumDialogComponent, {
      width: '300px',
      data: { socioNum: null },
      position: {top: '150px'}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getSocioInfo(result);
        this.loaded_socio = true;
      } else {
        this.toastr.error(null, 'No ha escrito ningún Número de Socio');
        this.router.navigate(['/home']);
      }
    });
  }

  getSocioInfo(socioNum) {
    this.dbservice.getSocio(socioNum).subscribe((data) => {
      if (data['success'] == 1 && data['data'].length > 0) {
        this.socio = data['data'][0];
        this.socio.fecha_alta = new Date(this.socio.fecha_alta).toLocaleDateString('es');

        // cargamos las variedades tras obtener un socio al que dispensar
        this.getVariedades();

      } else {
        this.toastr.error(null, data['msg']);
        this.router.navigate(['home']);
      }
    },
      (err) => {
        this.toastr.error(null, err.message);
        this.router.navigate(['home']);
      }
    );
  }

  getVariedades() {
    this.dbservice.getVariedades().subscribe((data) => {
      if (data['success'] == 1) {
        this.variedades = data['data'];
      } else {
        this.toastr.error(null, data['msg']);
      }
    },
      (err) => {
        this.toastr.error(null, err.message);
      }
    );
  }

  isEven(index) {
    if (index % 2 == 0) {
      return { 'background-color': '#4f4f4f35' };
    }
  }

  changeFormQuantity(plus: boolean) {
    if (plus) {
      this.formQuantity += 0.5;
    } else if (this.formQuantity > 0.5) {
      this.formQuantity -= 0.5;
    }
  }

  delete(i) {
    let orderToRemove = this.orders.splice(i, 1)[0];
    this.totalQuantity -= orderToRemove.quantity;
    this.totalAportacion = 0;
    for (let order of this.orders) {
      this.totalAportacion += (order.precio_variedad * order.quantity);
    }
  }

  addToOrder() {
    if (this.selectedVariety == null) {
      this.toastr.error(null, 'Seleccione variedad');
      return;
    }

    let variedadFiltrada = this.variedades.filter(variedad => variedad.nombre == this.selectedVariety)[0];

    // comprobamos si ya ha sido insertada para actualizar, si no, insertamos
    if (variedadFiltrada != null) {
      let previousOrder = this.orders.find(order => order.name == this.selectedVariety);
      if (previousOrder != null) {
        let index = this.orders.indexOf(previousOrder);
        this.orders[index].quantity += this.formQuantity;
        this.orders[index].aportacion = this.orders[index].quantity * variedadFiltrada.aporte
      } else {
        this.orders.push(
          {
            name: variedadFiltrada.nombre, quantity: this.formQuantity,
            aportacion: (this.formQuantity * variedadFiltrada.aporte), id_variedad: variedadFiltrada.id_variedad,
            precio_variedad: variedadFiltrada.aporte
          }
        );
      }

      this.totalQuantity = 0;
      this.totalAportacion = 0;
      for (let order of this.orders) {
        this.totalQuantity += order.quantity;
        this.totalAportacion += order.aportacion;
      }

      this.formQuantity = 0.5;
      this.selectedVariety = null;
    }

  }

  aportado() {
    if (this.orders.length == 0) {
      this.toastr.error(null, 'No hay pedidos');
      return;
    }

    this.contributing = true;

    this.dbservice.addAportaciones(this.orders, this.socio).subscribe((data) => {
      if (data['success'] == 1) {
        this.toastr.success(null, data['msg'], {
          toastClass: 'ngx-toastr toast-correcto'
        });
        this.router.navigate(['home']);
      } else {
        this.toastr.error(null, data['msg']);
      }
      this.contributing = false;
    },
      (err) => {
        this.toastr.error(null, err.message);
        this.contributing = false;
      }
    );
  }

}
