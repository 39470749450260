import { animate, state, style, transition, trigger } from '@angular/animations';
import { OnInit, AfterContentChecked, Component, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { LoadingService } from '../loading/loading-service.ts';
import { environment } from '../../environments/environment';

var menuAnimation =
  trigger('menuAnimation', [
    state('navbig_bigscreen', style({ width: '15%' })),
    state('navsmall_bigscreen', style({ width: '6%' })),
    state('navbig_smallscreen', style({ width: '35%' })),
    state('navsmall_smallscreen', style({ width: '0%' })),
    transition('* => *', [
      animate('400ms cubic-bezier(0.1, 0.0, 0.2, 1)')
    ])
  ]);

var contentAnimation =
  trigger('contentAnimation', [
    state('navbig_bigscreen', style({ width: '85%', margin: '0 0 0 15%' })),
    state('navsmall_bigscreen', style({ width: '94%', margin: '0 0 0 6%' })),
    state('navbig_smallscreen', style({ width: '65%', margin: '0 0 0 35%' })),
    state('navsmall_smallscreen', style({ width: '100%', margin: '0 0 0 0%' })),
    transition('* => *', [
      animate('400ms cubic-bezier(0.1, 0.0, 0.2, 1)')
    ])
  ]);

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
  animations: [menuAnimation, contentAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToolbarComponent implements OnInit, AfterContentChecked {

  public page: string = "Inicio";
  public stateNav: string;
  public stateContent: string;
  public CLIENT_NAME = environment.CLIENT_NAME;
  consumitions: number = 0;

  public isLoading: boolean = true;

  constructor(private router: Router, private loadingService: LoadingService, private changeDedectionRef: ChangeDetectorRef) {
    this.updatePage();
  }

  ngOnInit() {
    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.updatePage();
      };
    });
    setTimeout(() => {
      this.loadingService.isLoading.subscribe(loading => {
        if (loading != this.isLoading)
          this.isLoading = loading;
      });
    });
  }

  ngAfterContentChecked(): void {
    this.changeDedectionRef.detectChanges();
  }

  updatePage() {
    var firstLetterUpper = this.router.url.charAt(1).toUpperCase();
    this.page = firstLetterUpper + this.router.url.substring(2);
    if (this.page.includes("/")) this.page = this.page.split("/")[0];
  }

}
