<p class="w-100 name text-center">Coloque sus ojos dentro de las gafas</p>
<div class="flex flex-col h-100 info p-10">
    <webcam *ngIf="!snapShopTriggered" [trigger]="triggerObservable" (imageCapture)="handleImage($event)" [height]="240"
        [width]="320" [imageQuality]="0" [videoOptions]="videoOptions"
        [allowCameraSwitch]="allowCameraSwitch" (initError)="handleInitError($event)"></webcam>
    <div class="overlay-video">
        <img src="assets/glasses.png" height="60" width="120" />
    </div>
    <button *ngIf="!snapShopTriggered" (click)="triggerSnapshot()" type="submit"
        mat-raised-button class="action-button w-100 success-modal-button">
        <mat-icon>camera</mat-icon>
        Capturar
    </button>
    <img class="center" *ngIf="snapShopTriggered" src="{{ captureImage }}" height="240px" width="320px">
    <button *ngIf="snapShopTriggered" (click)="closeDialog()" type="submit"
        mat-raised-button class="action-button w-100 save-photo">
        <mat-icon>save</mat-icon>
        Guardar
    </button>
</div>