<div class="calendar">
  <ngx-daterangepicker-material [@moveAnimation]="movedListPanel" [locale]="locale"
    (choosedDate)="dateSelected($event)"> </ngx-daterangepicker-material>
</div>
<mat-card class="chart" [@moveAnimation]="movedListPanel">
  <mat-card-content>
    <canvas baseChart #chart [data]="lineChartData" [options]="lineChartOptions" [type]="lineChartType"></canvas>
  </mat-card-content>
</mat-card>
<div [@moveAnimation]="movedListPanel" class="header-line">
  <mat-card class="header-data">
    <mat-card-title class="title">Aportación recibida</mat-card-title>
    {{totalAportado}} €
  </mat-card>
  <mat-card class="header-data">
    <mat-card-title class="title">Aportación / socio</mat-card-title>
    {{aportadoPerMember}} €
  </mat-card>
  <mat-card class="header-data">
    <mat-card-title class="title">Aportación / día</mat-card-title>
    {{aportadoPerDay}} €
  </mat-card>
</div>
