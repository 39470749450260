import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})

export class AuthService {

  private loggedIn = new BehaviorSubject<boolean>(false);

  constructor() { }

  // para subscribirse a los cambios de la variable desde cualquier componente
  get isLoggedIn(): Observable<boolean> {
    return this.loggedIn.asObservable();
  }

  logout() {
    this.loggedIn.next(false);
  }

  logged() {
    this.loggedIn.next(true);
  }

}