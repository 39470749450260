import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AlmacenComponent } from './almacen/almacen.component';
import { DispensarioComponent } from './dispensario/dispensario.component';
import { EstadisticasComponent } from './estadisticas/estadisticas.component';
import { HomeComponent } from './home/home.component';
import { SociosComponent } from './socios/socios.component';

const routes: Routes = [
  { path: 'inicio', component: HomeComponent },
  { path: 'socios', component: SociosComponent },
  { path: 'dispensario', component: DispensarioComponent },
  { path: 'dispensario/:id', component: DispensarioComponent },
  { path: 'almacen', component: AlmacenComponent },
  { path: 'estadisticas', component: EstadisticasComponent },
  { path: '**', redirectTo: 'inicio', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
