<mat-card [@moveAnimation]="movedListPanel" class="home-card">
    <mat-card-content class="w-100">
        <div class="container">
            <div class="left">
                <a routerLink="/dispensario">
                    <div class="dispensario">
                        <mat-icon>shopping_cart</mat-icon>
                    </div>
                </a>
            </div>
            <div class="middle">
                <div class="scrollable">
                    <div *ngFor="let socio of socios" class="numberCircle" (click)="showSocioData(socio.id_socio)">
                        {{socio.id_socio}}
                    </div>
                </div>
            </div>
            <div class="right">
                <a (click)="newConsumition()">
                    <div class="consumiciones">
                        <mat-icon>local_cafe</mat-icon>
                        <span>{{consumitions}}</span>
                    </div>
                </a>
            </div>
        </div>
        <a class="scroll-zone">

        </a>
    </mat-card-content>
</mat-card>
<div class="wrapper">
    <a class="socios" routerLink="/socios">
        <mat-card>
            <mat-card-content>
                <mat-icon class="router-icon">people</mat-icon>
            </mat-card-content>
            <mat-card-actions>
                <button mat-raised-button class="router-button">
                    <span>Socios</span>
                </button>
            </mat-card-actions>
        </mat-card>
    </a>
    <a class="almacen" routerLink="/almacen">
        <mat-card>
            <mat-card-content>
                <mat-icon class="router-icon">inbox</mat-icon>
            </mat-card-content>
            <mat-card-actions>
                <button mat-raised-button class="router-button">
                    <span>Almacén</span>
                </button>
            </mat-card-actions>
        </mat-card>
    </a>
    <a class="estadisticas" routerLink="/estadisticas">
        <mat-card>
            <mat-card-content>
                <mat-icon class="router-icon">show_chart</mat-icon>
            </mat-card-content>
            <mat-card-actions>
                <button mat-raised-button class="router-button">
                    <span>Estadisticas</span>
                </button>
            </mat-card-actions>
        </mat-card>
    </a>
    <a class="informe" (click)="cerrarDia()">
        <mat-card>
            <mat-card-content>
                <mat-icon class="router-icon">list</mat-icon>
            </mat-card-content>
            <mat-card-actions>
                <button mat-raised-button class="router-button">
                    <span>Informe</span>
                </button>
            </mat-card-actions>
        </mat-card>
    </a>
</div>