import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { WebcamImage, WebcamInitError } from 'ngx-webcam';
import { Subject, Observable } from 'rxjs';

@Component({
  selector: 'app-dialog-webcam',
  templateUrl: './dialog-webcam.component.html',
  styleUrls: ['./dialog-webcam.component.scss']
})
export class DiaglogWebcamComponent {

  private trigger: Subject<any> = new Subject();
  public webcamImage!: WebcamImage;
  private nextWebcam: Subject<any> = new Subject();
  captureImage = '';
  imageChangedEvent: any = '';
  croppedImage: any = '';
  public snapShopTriggered: boolean = false;
  allowCameraSwitch = true;

  constructor(
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  public triggerSnapshot(): void {
    this.trigger.next();
    this.snapShopTriggered = true;
  }

  public handleImage(webcamImage: WebcamImage): void {
    this.webcamImage = webcamImage;
    this.captureImage = webcamImage!.imageAsDataUrl;
  }

  public get triggerObservable(): Observable<any> {
    return this.trigger.asObservable();
  }

  public get nextWebcamObservable(): Observable<any> {
    return this.nextWebcam.asObservable();
  }

  closeDialog() {
    this.dialogRef.close({ image: this.captureImage });
  }

  public get videoOptions(): MediaTrackConstraints {
    const result: MediaTrackConstraints = {
      width: { min: 160, ideal: 320 },
      height: { min: 120, ideal: 240 },
      facingMode: { ideal: 'environment' }
    };
    return result;
  }

  public handleInitError(error: WebcamInitError): void {
    if (error.mediaStreamError.name === "NotAllowedError") {
      console.warn("Camera access was not allowed by user!");
    }
    if (error.mediaStreamError) console.warn(error.mediaStreamError)
  }

}