<!-- AÑADIR SOCIO -->

<mat-accordion>
    <mat-expansion-panel class="add-expansion" [expanded]="false">
        <mat-expansion-panel-header>
            <mat-panel-title>
                <mat-icon>person_add</mat-icon><a class="icon">Nuevo socio</a>
            </mat-panel-title>
        </mat-expansion-panel-header>
        <form [formGroup]="addSocioForm">
            <div class="profile-photo-container">
                <img (click)="takePhoto()" class="profile-photo"
                    src="{{ captureImage !== '' ? captureImage : 'assets/add_photo.png' }}">
            </div>
            <mat-form-field class="input-field">
                <input id="numerosocio_add" type="text" formControlName="numerosocio" matInput placeholder="Nº Socio">
            </mat-form-field>
            <mat-form-field class="input-field">
                <input id="nombre_add" type="text" formControlName="nombre" matInput placeholder="Nombre">
            </mat-form-field>
            <mat-form-field class="input-field">
                <input id="apellidos_add" type="text" formControlName="apellidos" matInput placeholder="Apellidos">
            </mat-form-field>
            <mat-form-field class="input-field">
                <input id="direccion_add" type="text" formControlName="direccion" matInput placeholder="Dirección">
            </mat-form-field>
            <mat-form-field class="input-field">
                <input id="dni_add" type="number" formControlName="dni" matInput placeholder="DNI">
            </mat-form-field>
            <mat-form-field class="input-field">
                <input id="aval_add" type="number" matInput formControlName="aval" placeholder="Aval">
            </mat-form-field>
        </form>
        <button type="submit" mat-raised-button class="action-button dark-green-bgcolor" [disabled]="addSocioForm.invalid"
            (click)="addSocio()">
            <mat-icon>control_point</mat-icon><span class="icon">Añadir</span>
        </button>
        <button mat-raised-button class="action-button clean-button" (click)="resetForm()">
            <mat-icon>replay</mat-icon><span class="icon"></span>Limpiar
        </button>
    </mat-expansion-panel>
</mat-accordion>

<!-- LISTADO SOCIOS-->

<mat-accordion>
    <mat-expansion-panel [@moveAnimation]="movedListPanel" class="list-expansion" [expanded]="true">
        <mat-expansion-panel-header>
            <mat-panel-title>
                <mat-icon>people</mat-icon><a class="icon">Listado socios</a>
            </mat-panel-title>
        </mat-expansion-panel-header>
        <mat-form-field class="listado-filter">
            <input matInput (keyup)="applyFilter($event.target.value)">
            <mat-placeholder>
                <mat-icon>search</mat-icon> Buscar socio
            </mat-placeholder>
        </mat-form-field>
        <table mat-table [dataSource]="dataSource" multiTemplateDataRows class="w-100 mat-elevation-z8">
            <ng-container [matColumnDef]="column.key" *ngFor="let column of tableDef">
                <th mat-header-cell *matHeaderCellDef> {{column.header}} </th>
                <tr>
                    <td mat-cell *matCellDef="let element"> {{element[column.key]}} </td>
                </tr>
            </ng-container>
            <ng-container matColumnDef="expandedDetail">
                <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
                    <div class="item-detail" [@detailExpand]="element === expandedElement ? 'expanded' : 'collapsed'">
                        <mat-card class="detail">
                            <div class='detalles'>
                                <div>
                                    <h3>Detalles de {{element.nombre}} {{element.apellidos}}</h3>
                                    <img *ngIf="edit" (click)="takePhoto()" alt="Profile photo" class="profile-photo" src="{{ captureImage !== '' ? captureImage : 'assets/add_photo.png' }}">
                                    <img *ngIf="!edit" alt="Profile photo" class="profile-photo" src="profile_photos/{{element.filename}}.jpeg" onerror="this.src='assets/add_photo.png'">
                                </div>
                                <mat-divider></mat-divider>
                                <div class="floating-buttons-right">
                                    <button *ngIf="!edit" class="edit-button" (click)="editableSocio()"
                                        mat-raised-button>
                                        <mat-icon>edit</mat-icon>
                                    </button>
                                    <button *ngIf="edit" class="save-button" (click)="modifySocio(element)"
                                        mat-raised-button>
                                        <mat-icon>save</mat-icon>
                                    </button>
                                    <button *ngIf="!edit" class="delete-button" (click)="deleteSocio(element.id_socio)"
                                        mat-raised-button>
                                        <mat-icon>delete_forever</mat-icon>
                                    </button>
                                </div>
                                <div class="edit-container">
                                    <mat-form-field class="input-field">
                                        <input [readonly]="true" type="number" matInput placeholder="Nº Socio"
                                            [(ngModel)]="element.id_socio" value="{{element.id_socio}}">
                                    </mat-form-field>
                                    <mat-form-field class="input-field">
                                        <input [readonly]="true" type="text" matInput placeholder="Aval"
                                            [(ngModel)]="element.aval" value="{{element.aval}}">
                                    </mat-form-field>
                                    <mat-form-field class="input-field">
                                        <input [readonly]="!edit" type="text" matInput [(ngModel)]="element.nombre"
                                            placeholder="Nombre" value="{{element.nombre}}">
                                        <span class="edit-icon" *ngIf="edit" matPrefix>
                                            <mat-icon>edit</mat-icon>
                                        </span>
                                    </mat-form-field>
                                    <mat-form-field class="input-field">
                                        <input [readonly]="!edit" type="text" matInput [(ngModel)]="element.apellidos"
                                            placeholder="Apellidos" value="{{element.apellidos}}">
                                        <span class="edit-icon" *ngIf="edit" matPrefix>
                                            <mat-icon>edit</mat-icon>
                                        </span>
                                    </mat-form-field>
                                    <mat-form-field class="input-field">
                                        <input [readonly]="!edit" type="text" matInput [(ngModel)]="element.dni"
                                            placeholder="DNI" value="{{element.dni}}">
                                        <span class="edit-icon" *ngIf="edit" matPrefix>
                                            <mat-icon>edit</mat-icon>
                                        </span>
                                    </mat-form-field>
                                    <mat-form-field class="input-field">
                                        <input [readonly]="!edit" type="text" matInput [(ngModel)]="element.direccion"
                                            placeholder="Dirección" value="{{element.direccion}}">
                                        <span class="edit-icon" *ngIf="edit" matPrefix>
                                            <mat-icon>edit</mat-icon>
                                        </span>
                                    </mat-form-field>
                                </div>
                            </div>
                        </mat-card>
                    </div>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let element; columns: displayedColumns;" class="element-row"
                [class.expanded-row]="expandedElement === element"
                (click)="expandedElement = expandedElement === element && edit ? null : element">
            </tr>
            <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></tr>
        </table>
        <mat-paginator #paginator [pageSizeOptions]="[5, 10, 15]" showFirstLastButtons></mat-paginator>
    </mat-expansion-panel>
</mat-accordion>

<!-- LISTADO SOCIOS BAJA -->

<mat-accordion class="accordion">
    <mat-expansion-panel class="list-expansion" [expanded]="false" [@moveAnimation]="movedListPanel">
        <mat-expansion-panel-header>
            <mat-panel-title>
                <mat-icon>people</mat-icon><a class="icon">Listado socios dados de baja</a>
            </mat-panel-title>
        </mat-expansion-panel-header>
        <mat-form-field class="listado-filter">
            <input matInput (keyup)="applyFilterBaja($event.target.value)">
            <mat-placeholder>
                <mat-icon>search</mat-icon> Buscar socio
            </mat-placeholder>
        </mat-form-field>
        <table mat-table [dataSource]="dataSourceBaja" multiTemplateDataRows class="w-100 mat-elevation-z8">
            <ng-container [matColumnDef]="column.key" *ngFor="let column of tableDef">
                <th mat-header-cell *matHeaderCellDef> {{column.header}} </th>
                <tr>
                    <td mat-cell *matCellDef="let element"> {{element[column.key]}} </td>
                </tr>
            </ng-container>
            <ng-container matColumnDef="expandedDetail">
                <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
                    <div class="item-detail" [@detailExpand]="element === expandedElement ? 'expanded' : 'collapsed'">
                        <mat-card class="detail">
                            <div class='detalles'>
                                <div>
                                    <h3>Detalles de {{element.nombre}} {{element.apellidos}}</h3>
                                </div>
                                <mat-divider></mat-divider>
                                <div class="floating-buttons-right">
                                    <button class="save-button" (click)="altaSocio(element.id_socio)" mat-raised-button>
                                        <mat-icon>person_add</mat-icon>
                                    </button>
                                </div>
                                <div class="edit-container">
                                    <mat-form-field class="input-field">
                                        <input [readonly]="true" type="number" matInput placeholder="Nº Socio"
                                            [(ngModel)]="element.id_socio" value="{{element.id_socio}}">
                                    </mat-form-field>
                                    <mat-form-field class="input-field">
                                        <input [readonly]="true" type="text" matInput placeholder="Aval"
                                            [(ngModel)]="element.aval" value="{{element.aval}}">
                                    </mat-form-field>
                                    <mat-form-field class="input-field">
                                        <input [readonly]="true" type="text" matInput [(ngModel)]="element.nombre"
                                            placeholder="Nombre" value="{{element.nombre}}">
                                    </mat-form-field>
                                    <mat-form-field class="input-field">
                                        <input [readonly]="true" type="text" matInput [(ngModel)]="element.apellidos"
                                            placeholder="Apellidos" value="{{element.apellidos}}">
                                    </mat-form-field>
                                    <mat-form-field class="input-field">
                                        <input [readonly]="true" type="text" matInput [(ngModel)]="element.dni"
                                            placeholder="DNI" value="{{element.dni}}">
                                    </mat-form-field>
                                    <mat-form-field class="input-field">
                                        <input [readonly]="true" type="text" matInput [(ngModel)]="element.direccion"
                                            placeholder="Dirección" value="{{element.direccion}}">
                                    </mat-form-field>
                                </div>
                            </div>
                        </mat-card>
                    </div>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let element; columns: displayedColumns;" class="element-row"
                [class.expanded-row]="expandedElement === element"
                (click)="expandedElement = expandedElement === element && edit ? null : element">
            </tr>
            <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></tr>
        </table>
        <mat-paginator #paginatorBaja [pageSizeOptions]="[5, 10, 15]" showFirstLastButtons></mat-paginator>
    </mat-expansion-panel>
</mat-accordion>