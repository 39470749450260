import { formatDate, registerLocaleData } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import localeES from "@angular/common/locales/es";
import { Injectable } from '@angular/core';
import * as shajs from 'sha.js';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DbService {
  readonly API_URL = environment.API_URL;

  constructor(private http: HttpClient) {
    registerLocaleData(localeES);
  }

  private encryptPassword(pass: string): string {
    try {
      return shajs('sha1').update(pass).digest('hex');
    }
    catch (err) {
      console.error(err)
      return null;
    }
  };

  // ----------------------------------- LOGIN ---------------------------------------------
  public login(login) {
    login.pass = this.encryptPassword(login.pass);
    return this.http.post(this.API_URL + '/login', JSON.stringify(login));
  };


  // ----------------------------------- HOME ---------------------------------------------
  public aportacionesHoy() {
    return this.http.get(this.API_URL + '/aportaciones/hoy');
  };

  // ----------------------------------- ESTADÍSTICAS ---------------------------------------------
  public estadisticas(dates) {
    return this.http.post(this.API_URL + '/estadisticas', JSON.stringify(dates));
  };

  // ----------------------------------- DISPENSARIO --------------------------------------

  public addAportaciones(orders: any, socio) {
    let today = formatDate(new Date(), 'yyyy-MM-dd HH:mm:ss', 'es');

    let aportaciones = [];
    for (let i = 0; i < orders.length; i++) {
      aportaciones.push([socio.id_socio, orders[i].id_variedad,
      orders[i].quantity, orders[i].aportacion, today]);
    }

    return this.http.post(this.API_URL + '/aportaciones/add', aportaciones);
  }

  public downloadAportacionesHoy() {
    return this.http.get(this.API_URL + '/aportaciones/cerrar-dia');
  }

  // ----------------------------------- CONSUMICIONES --------------------------------------

  public getConsumiciones(dates) {
    return this.http.post(this.API_URL + '/consumiciones', JSON.stringify(dates));
  }

  public addConsumicion() {
    let today = formatDate(new Date(), 'yyyy-MM-dd HH:mm:ss', 'es');
    return this.http.post(this.API_URL + '/consumiciones/add', JSON.stringify({ date: today }));
  }

  // ----------------------------------- SOCIOS -------------------------------------------

  public getSocio(socioNum) {
    let json = {
      id_socio: socioNum
    };

    return this.http.post(this.API_URL + '/socios/get', JSON.stringify(json));
  };

  public getSocios() {
    return this.http.get(this.API_URL + '/socios');
  };

  public getSociosBaja() {
    return this.http.get(this.API_URL + '/socios/baja');
  };

  public addSocio(socio) {
    return this.http.post(this.API_URL + '/socios/add', JSON.stringify(socio));
  };

  public altaSocio(numero) {
    let json = {
      id_socio: numero
    };

    return this.http.post(this.API_URL + '/socios/alta', JSON.stringify(json));
  };

  public updateSocio(socio) {
    return this.http.post(this.API_URL + '/socios/update', JSON.stringify(socio));
  };

  public deleteSocio(numero) {
    let json = {
      id_socio: numero
    };

    return this.http.post(this.API_URL + '/socios/baja', JSON.stringify(json));
  };

  public getTodayAportacionesSocio(idSocio) {
    let json = {
      idSocio
    };
    return this.http.post(this.API_URL + '/socios/aportaciones-hoy', JSON.stringify(json));
  }

  // ----------------------------------- ALMACEN ---------------------------------------------

  public getVariedades() {
    return this.http.get(this.API_URL + '/variedades');
  };

  public addVariedad(variedad) {
    return this.http.post(this.API_URL + '/variedades/add', JSON.stringify(variedad));
  };

  public updateVariedad(variedad) {
    return this.http.post(this.API_URL + '/variedades/update', JSON.stringify(variedad));
  };

  public deleteVariedad(id_variedad) {
    let json = {
      id_variedad: id_variedad
    };

    return this.http.post(this.API_URL + '/variedades/delete', JSON.stringify(json));
  };

}
