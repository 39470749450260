<div class="grid-container">
    <div class="socio cell-grid left">
        <div class="socio-info">
            <div>
                <h2><b>Socio Nº {{socio.id_socio}}</b></h2>
                <img alt="Socio icon" class="socio-icon" *ngIf="!loaded_socio" src="assets/socio_icon.png">
                <img alt="Socio icon" class="socio-icon profile-photo" *ngIf="loaded_socio" src="profile_photos/{{socio.filename}}.jpeg" onerror="this.src='assets/socio_icon.png'">
                <div class="info-container">
                    <div class="info"><b>{{socio.nombre}}</b></div>
                    <div class="info"><b>{{socio.apellidos}}</b></div>
                    <div class="info"><b>Alta: {{socio.fecha_alta}}</b></div>
                </div>
            </div>
        </div>
    </div>

    <div class="TICKET cell-grid">
        <div class="table-ticket">
            <tr class="dark-green-bgcolor">
                <td>Variedad</td>
                <td class="centered_text">Cantidad</td>
                <td class="centered_text">Aportación</td>
                <td class="centered_text" class="dark-green-color">Borra</td>
            </tr>
            <div class="content-ticket">
                <tr *ngFor="let order of orders; let i = index" [attr.data-index]="i">
                    <td [ngStyle]="isEven(i)">{{order.name}}</td>
                    <td [ngStyle]="isEven(i)" class="centered_text">{{order.quantity}} grs</td>
                    <td [ngStyle]="isEven(i)" class="centered_text">{{order.aportacion}} €</td>
                    <td [ngStyle]="isEven(i)" class="centered_text delete-icon ">
                        <mat-icon class="cursor-pointer" (click)="delete(i)">delete_outline</mat-icon>
                    </td>
                </tr>
            </div>
            <tr class="totals">
                <td>Total:</td>
                <td class="centered_text">{{totalQuantity}} grs</td>
                <td class="centered_text">{{totalAportacion}} €</td>
                <td class="centered_text" class="dark-green-color">Bor</td>
            </tr>
        </div>
    </div>

    <div class="FORM cell-grid left">
        <div class="form-fields">
            <div class="mt-minus5px">
                <mat-form-field class="w-100 mr-10px ml-3">
                    <mat-label>Variedad</mat-label>
                    <mat-select class="fs20" [(value)]="selectedVariety">
                        <mat-option *ngFor="let variedad of variedades; let i = index" value="{{variedad.nombre}}">
                            {{variedad.nombre}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <div class="ml-3">
                    <tr>
                        <td>
                            <mat-icon (click)="changeFormQuantity(false)" class="cursor-pointer fs30">
                                remove_circle_outline</mat-icon>
                        </td>
                        <td class="w-100 text-center fs30">{{formQuantity}} gr</td>
                        <td>
                            <mat-icon (click)="changeFormQuantity(true)" class="cursor-pointer fs30">
                                add_circle_outline</mat-icon>
                        </td>
                    </tr>
                </div>
            </div>
            <div>
                <button (click)="addToOrder()" type="submit" mat-raised-button
                    class="action-button w-100 success-modal-button">
                    <mat-icon>control_point</mat-icon>
                </button>
                <button #submit [disabled]="contributing" type="submit" (click)="aportado()" mat-raised-button
                    class="action-button w-100 finish">
                    Aportado
                </button>
            </div>
        </div>
    </div>
</div>