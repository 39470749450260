import { HttpErrorResponse, HttpEvent, HttpEventType, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError, tap } from "rxjs/operators";
import { LoadingService } from './loading/loading-service.ts';

@Injectable({
  providedIn: 'root',
})
export class AuthInterceptorService implements HttpInterceptor {


  constructor(private loadingService: LoadingService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    console.debug("Interception In Progress");

    this.loadingService.startLoading();

    const token: string = localStorage.getItem('token');
    req = req.clone({ headers: req.headers.set('Authorization', 'Bearer ' + token) });
    req = req.clone({ headers: req.headers.set('Content-Type', 'application/json') });
    req = req.clone({ headers: req.headers.set('Accept', 'application/json') });

    return next.handle(req)
      .pipe(
        tap((ev: HttpEvent<any>) => {
          if (ev.type == HttpEventType.Response) {
            this.loadingService.stopLoading();
          }
        }),
        catchError((error: HttpErrorResponse) => {
          this.loadingService.stopLoading();
          //401 UNAUTHORIZED - SECTION 2
          if (error && error.status === 401) {
            console.info("ERROR 401 UNAUTHORIZED")
          }
          const err = error.error.message || error.statusText;
          return throwError(error);
        })
      );
  }
}