import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface SocioNumDialogData {
  socioNum: string;
}

@Component({
  selector: 'app-dialog-socio',
  templateUrl: './socio-num-dialog.component.html',
})
export class SocioNumDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<SocioNumDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: SocioNumDialogData) { }

  onNoClick() {
    this.dialogRef.close();
  }

  ngOnInit() {
    var input = document.getElementById("membernumber");

    input.addEventListener("keypress", function (event) {
      if (event.key === "Enter") {
        event.preventDefault();
        document.getElementById("submit").click();
      }
    });
  }

}