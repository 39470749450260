import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})

export class LoadingService {

  private loading = new BehaviorSubject<boolean>(false);

  constructor() { }

  // para subscribirse a los cambios de la variable desde cualquier componente
  get isLoading(): Observable<boolean> {
    return this.loading.asObservable();
  }

  stopLoading() {
    this.loading.next(false);
  }

  startLoading() {
    this.loading.next(true);
  }

}