<mat-accordion class="add-accordion">
    <mat-expansion-panel class="list-expansion" [expanded]="false">
        <mat-expansion-panel-header>
            <mat-panel-title>
                <mat-icon>person_add</mat-icon><a class="icon">Nueva variedad</a>
            </mat-panel-title>
        </mat-expansion-panel-header>
        <form [formGroup]="addVariedadForm">
            <mat-form-field class="input-field">
                <input id="nombre_add" type="text" formControlName="nombre" matInput placeholder="Nombre">
            </mat-form-field>
            <mat-form-field class="input-field">
                <input id="cantidad_add" type="text" formControlName="cantidad" matInput placeholder="Cantidad">
                <span matSuffix>grs.</span>
            </mat-form-field>
            <mat-form-field class="input-field">
                <input id="aporte_add" type="text" formControlName="aporte" matInput placeholder="Aporte">
                <span matSuffix>€/gr.</span>
            </mat-form-field>
        </form>
        <button type="submit" mat-raised-button class="action-button dark-green-bgcolor"
            [disabled]="addVariedadForm.invalid" (click)="addVariedad()">
            <mat-icon>control_point</mat-icon><span class="icon">Añadir</span>
        </button>
        <button mat-raised-button class="action-button clean-button" (click)="resetForm()">
            <mat-icon>replay</mat-icon><span class="icon"></span>Limpiar
        </button>
    </mat-expansion-panel>
</mat-accordion>
<mat-accordion class="accordion">
    <mat-expansion-panel class="list-expansion" [expanded]="true" [@moveAnimation]="movedListPanel">
        <mat-expansion-panel-header>
            <mat-panel-title>
                <mat-icon>people</mat-icon><a class="icon">Listado variedades</a>
            </mat-panel-title>
        </mat-expansion-panel-header>
        <mat-form-field class="listado-filter">
            <input matInput (keyup)="applyFilter($event.target.value)">
            <mat-placeholder>
                <mat-icon>search</mat-icon> Buscar variedad
            </mat-placeholder>
        </mat-form-field>
        <table mat-table [dataSource]="dataSource" multiTemplateDataRows class="w-100 mat-elevation-z8">
            <ng-container [matColumnDef]="column.key" *ngFor="let column of tableDef">
                <th mat-header-cell *matHeaderCellDef> {{column.header}} </th>
                <tr *ngIf="isAporte(column)">
                    <td mat-cell *matCellDef="let element">{{element[column.key]}} €</td>
                </tr>
                <tr *ngIf="!isAporte(column)">
                    <td mat-cell *matCellDef="let element">{{element[column.key]}}</td>
                </tr>
            </ng-container>
            <ng-container matColumnDef="expandedDetail">
                <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
                    <div class="item-detail" [@detailExpand]="element === expandedElement ? 'expanded' : 'collapsed'">
                        <mat-card class="detail">
                            <div class='detalles'>
                                <div>
                                    <h3>Detalles variedad {{element.nombre}}</h3>
                                </div>
                                <mat-divider></mat-divider>
                                <div class="floating-buttons-right">
                                    <button *ngIf="!edit" class="edit-button" (click)="editableVariedad()"
                                        mat-raised-button>
                                        <mat-icon>edit</mat-icon>
                                    </button>
                                    <button *ngIf="edit" class="save-button" (click)="modifyVariedad(element)"
                                        mat-raised-button>
                                        <mat-icon>save</mat-icon>
                                    </button>
                                    <button *ngIf="!edit" class="delete-button"
                                        (click)="deleteVariedad(element.id_variedad)" mat-raised-button>
                                        <mat-icon>delete_forever</mat-icon>
                                    </button>
                                </div>
                                <div class="edit-container">
                                    <mat-form-field class="input-field">
                                        <input [readonly]="!edit" type="text" matInput [(ngModel)]="element.nombre"
                                            placeholder="Nombre" value="{{element.nombre}}">
                                        <span class="edit-icon" *ngIf="edit" matPrefix>
                                            <mat-icon>edit</mat-icon>
                                        </span>
                                    </mat-form-field>
                                    <mat-form-field class="input-field">
                                        <input [readonly]="!edit" type="number" matInput placeholder="Cantidad"
                                            [(ngModel)]="element.cantidad" value="{{element.cantidad}}">
                                        <span class="edit-icon" *ngIf="edit" matPrefix>
                                            <mat-icon>edit</mat-icon>
                                        </span>
                                        <span matSuffix>grs.</span>
                                    </mat-form-field>
                                    <mat-form-field class="input-field">
                                        <input [readonly]="!edit" type="text" matInput placeholder="Aporte"
                                            [(ngModel)]="element.aporte" value="{{element.aporte}}">
                                        <span class="edit-icon" *ngIf="edit" matPrefix>
                                            <mat-icon>edit</mat-icon>
                                        </span>
                                        <span matSuffix>€/gr.</span>
                                    </mat-form-field>
                                </div>
                            </div>
                        </mat-card>
                    </div>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let element; columns: displayedColumns;" class="element-row"
                [class.expanded-row]="expandedElement === element"
                (click)="expandedElement = expandedElement === element && edit ? null : element">
            </tr>
            <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></tr>
        </table>
        <mat-paginator class="paginator" [pageSizeOptions]="[5, 10, 15]" showFirstLastButtons></mat-paginator>
    </mat-expansion-panel>
</mat-accordion>